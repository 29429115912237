<template>
  <apexchart
    :type="isAISearch ? 'line' : 'area'"
    height="350"
    :options="chartOptions"
    :series="series"
  ></apexchart>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "LineChart",

  components: {
    apexchart: VueApexCharts,
  },

  props: {
    cats: {
      type: Array,
      required: true,
    },
    names: {
      type: Array,
      required: false,
    },
    isAISearch: {
      type: Boolean,
      default: false,
      required: false,
    },
    series: {
      type: Array,
      required: false,
    }
  },

  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#ABB3BF", "#0044A9"],
        stroke: {
          curve: "smooth",
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: this.cats,
          labels: {
            show: false,
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
          fontSize: "18px",
          fontFamily: "Aeonik",
          markers: {
            width: 20,
            height: 4,
            radius: 0,
          },
        },
      },
    };
  },

};
</script>
